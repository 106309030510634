<script>
import SvgIcon from "../UI/SvgIcon/index.vue";

export default {
  name: "ModalComponent",
  components: {SvgIcon},
  data: () => ({
    view: null,
    props: {}
  }),
  methods: {
    open(view, props) {
      this.props = props;
      this.view = view;
    },
    close() {
      this.view = null;
    },
    outerClick(e) {
      if (e.target.classList.contains("modal_plane")) {
        this.close();
      }
    }
  }
}
</script>

<template>
<div v-if="view">
  <div class="modal_plane" @click="outerClick">
    <div class="common-white-container pt-36 pb-32 pl-32 pr-32">
      <div class="close" @click="close">
        <svg-icon icon="close"></svg-icon>
      </div>
      <component :is="view" v-bind="props"></component>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.modal_plane {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: rgba(27, 31, 48, 0.32);
  backdrop-filter: blur(0.6px);
}
.common-white-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.close {
  position: absolute;
  right: 16px;
  top: 12px;
  color: #A3B0C5;
  cursor: pointer;
}
</style>