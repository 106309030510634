<template>
  <aside class="sidebar"
         v-cloak
         :class="{'collapsed': sidebarIsCollapsed}">
    <div class="sidebar-content">
      <div class="top-part">
        <nav class="sidebar-links">
          <ul v-if="!sidebarIsCollapsed"
              class="links-container">
            <template v-for="menuItem in sidebarRouteMenu">
              <template v-if="menuItem.submenuList">
                <li class="link sub-menu-container">
                  <sidebar-accordion initial-height="auto"
                                     :open-on-init="openOnInitCalculate(menuItem.categoryTitle)"
                                     @user-accordion-action="userAccordionAction($event, menuItem.categoryTitle)"
                                     color="var(--neutral-900)">
                    <template v-slot:header>
                      <div class="link-icon">
                        <svg-icon :icon="menuItem.categoryIcon"/>
                      </div>
                      <div class="link-text"
                           @click="sidebarAccordionClicked(menuItem.categoryTitle)">
                        {{ menuItem.categoryTitle }}
                      </div>
                    </template>
                    <template v-slot:accordion-body>
                      <div class="link-sublist">
                        <template v-for="menuSubitem in menuItem.submenuList">
                          <template v-if="menuSubitem.onlyForBeta !== true || hasBetaCookie()">
                            <router-link :to="menuSubitem.route"
                                         class="sublist-item"
                                         exact>
                              <span class="sublist-item-title" :data-intercom="menuSubitem.title">
                                {{ menuSubitem.title }}
                                <span class="counter">
                                  {{ getCounter(dataCounts, menuSubitem.title, menuItem.categoryTitle) }}
                                </span>
                              </span>
                            </router-link>
                          </template>
                        </template>
                      </div>
                    </template>
                  </sidebar-accordion>
                </li>
              </template>
              <template v-else>
                <router-link class="link"
                             tag="li"
                             :to="menuItem.route"
                             exact>
                  <div class="link-icon">
                    <svg-icon :icon="menuItem.icon"/>
                  </div>
                  <div class="link-text">
                    {{ menuItem.title }}
                  </div>
                </router-link>
              </template>
            </template>
          </ul>

          <div v-else
               class="collapsed-menu">
            <template v-for="menuItem in sidebarRouteMenu">
              <template v-if="menuItem.submenuList">
                <li class="collapsed-link sub-menu-container">
                  <div class="link-icon">
                    <svg-icon :icon="menuItem.categoryIcon"/>
                  </div>
                  <div class="link-content with-accordion">
                    <sidebar-accordion initial-height="auto"
                                       :open-on-init="true"
                                       color="var(--neutral-900)">
                      <template v-slot:header>
                        <div class="link-text"
                             @click="sidebarAccordionClicked(menuItem.categoryTitle)">
                          {{ menuItem.categoryTitle }}
                        </div>
                      </template>
                      <template v-slot:accordion-body>
                        <div class="link-sublist">
                          <template v-for="menuSubitem in menuItem.submenuList">
                            <template v-if="menuSubitem.onlyForBeta !== true || hasBetaCookie()">
                              <router-link :to="menuSubitem.route"
                                           class="sublist-item"
                                           exact>

                              <span class="sublist-item-title" :data-intercom="menuSubitem.title">
                                {{ menuSubitem.title }}
                                <span class="counter">
                                  {{ getCounter(dataCounts, menuSubitem.title, menuItem.categoryTitle) }}
                                </span>
                              </span>
                              </router-link>
                            </template>
                          </template>
                        </div>
                      </template>
                    </sidebar-accordion>
                  </div>
                </li>
              </template>
              <template v-else>
                <router-link class="collapsed-link"
                             tag="li"
                             :to="menuItem.route"
                             exact>
                  <div class="link-icon">
                    <svg-icon :icon="menuItem.icon"/>
                  </div>
                  <div class="link-content">
                    <div class="link-text">
                      {{ menuItem.title }}
                    </div>
                  </div>
                </router-link>
              </template>
            </template>
          </div>
        </nav>
      </div>

      <div class="bottom-part"
           v-if="userLimits && !sidebarIsCollapsed">

        <div class="limits-short-block">
          <div class="limits-content">
            <div class="limit-item">
              <div class="icon">
                <svg-icon icon="my-apps-menu-icon" color="#7C80C2" />
              </div>
              <div class="value">{{ userLimits?.max_apps?.used ?? 0 }}/{{
                  shortenDigits(userLimits?.max_apps?.total) ?? 0
                }}
              </div>
            </div>
            <div class="limit-item">
              <div class="icon">
                <svg-icon icon="key-icon" color="#7C80C2" />
              </div>
              <div class="value">{{ userLimits?.max_keywords?.used ?? 0 }}/{{
                  shortenDigits(userLimits?.max_keywords?.total) ?? 0
                }}
              </div>
            </div>
            <div class="limit-item"
                 v-if="userSubscription?.plan_name === 'Free'">
              <div class="icon">
                <svg-icon icon="competitors-menu-icon" color="#7C80C2"/>
              </div>
              <div class="value">{{ userLimits?.max_competitors?.used ?? 0 }}/{{
                  shortenDigits(userLimits?.max_competitors?.total) ?? 0
                }}
              </div>
            </div>
          </div>
          <div class="popup-details">
            <div class="user-limits">
              <div class="user-limits-details">
                <div class="user-limits-item">
                  <div class="limit-name">Apps:</div>
                  <div class="limit-value">{{ userLimits?.max_apps?.used ?? 0 }} of {{
                      shortenDigits(userLimits?.max_apps?.total) ?? 0
                    }}
                  </div>
                </div>

                <div class="user-limits-item">
                  <div class="limit-name">Keywords:</div>
                  <div class="limit-value">{{ userLimits?.max_keywords?.used ?? 0 }} of
                    {{ shortenDigits(userLimits?.max_keywords?.total) ?? 0 }}
                  </div>
                </div>

                <div class="user-limits-item"
                     v-if="userSubscription?.plan_name === 'Free'">
                  <div class="limit-name">Competitors:</div>
                  <div class="limit-value">{{ userLimits?.max_competitors?.used ?? 0 }} of
                    {{ shortenDigits(userLimits?.max_competitors?.total) ?? 0 }}
                  </div>
                </div>
              </div>
            </div>
            <div class="user-limits-about collapse-block">
              <a :href="envBlogUrl + 'pricing'"
                 class="more-info-link"
                 v-if="!sidebarIsCollapsed">
                <span>Upgrade Now</span>
                <svg-icon icon="arrow"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sidebar-trigger"
         @click="triggerSidebarMode">
      <div class="link-icon">
        <svg-icon icon="arrow-custom"/>
      </div>
    </div>
  </aside>
</template>

<script>
import Accordion from '@/components/UI/Accordion/index';
import SidebarMenuAccordion from "@/components/UI/SidebarMenuAccordion/index.vue";
import CurrentApp from '@/components/UI/CurrentApp/index';
import SidebarMenuItem from '@/components/TheSidebar/SidebarMenuItem/index';
import CurrentAppSkeletonBox from '@/components/TheSidebar/CurrentAppSkeletonBox/index';
import {mapGetters} from "vuex";
import {menu} from "@/sidebar-menu/index.js";
import {shortenDigits, getCookie, setCookie, hasBetaCookie} from "@/utils/utils";
import Label from '@/components/UI/Label/index';
import {httpRequest} from "@/api";
import store from "@/store";
import {getCounter} from "@/sidebar-menu/counters.js";

export default {
  name: "TheSidebar",
  data() {
    return {
      env: process.env.VUE_APP_URL,
      envBlogUrl: process.env.VUE_APP_BLOG_URL,
      sidebarRouteMenu: menu,
      betaTesterCookie: null,
      contactFormData: {
        content: '',
      },
      showFeatureModal: false,
      showSuccessModal: false,
      recalculateHeightCounter: 0,
      reOpenDropdownCounter: 0,
      accordionActiveOptions: {},
    }
  },
  components: {
    'custom-accordion': Accordion,
    'sidebar-accordion': SidebarMenuAccordion,
    'sidebar-menu-item': SidebarMenuItem,
    'current-app': CurrentApp,
    'current-app-skeleton-box': CurrentAppSkeletonBox,
    'menu-label': Label,
  },
  methods: {
    getCounter,
    hasBetaCookie,
    triggerSidebarMode() {
      this.$store.dispatch('TRIGGER_SIDEBAR', !this.sidebarIsCollapsed);
      localStorage.setItem('sidebarCollapsed', this.sidebarIsCollapsed);

      const accordionCookie = getCookie('sidebarMenuActiveOptions');
      if (accordionCookie) {
        for (const key in this.sidebarRouteMenu) {
          const accordionOptions = JSON.parse(accordionCookie);
          this.accordionActiveOptions[this.sidebarRouteMenu[key]?.categoryTitle] = true;
          this.accordionActiveOptions = {...this.accordionActiveOptions, ...accordionOptions};
        }
      }

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 190);
    },
    openFeatureModal() {
      this.showFeatureModal = true;
    },
    async sendContactForm() {
      if (this.contactFormData.content?.length === 0) {
        return;
      }

      const formdata = new FormData();
      formdata.append("author_name", this.userData.name);
      formdata.append("author_email", this.userData.email);
      formdata.append("from_url", window.location.origin + this.$route?.path);
      formdata.append("content", this.contactFormData.content);
      formdata.append("type", "request-feature");
      const result = await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.user.CONTACTS, formdata);

      if (!result?.errors && !result?.message) {
        this.showSuccessModal = true;
        this.contactFormData.content = '';
      } else {
        this.showSuccessModal = false;
      }

      this.showFeatureModal = false;
    },
    recalculateHeightCounterHandler() {
      this.recalculateHeightCounter++;
    },
    shortenDigits(val) {
      return shortenDigits(val);
    },
    userAccordionAction(event, data) {
      const accordionCookie = getCookie('sidebarMenuActiveOptions');
      const cookieDomain = process.env.VUE_APP_COOKIE_DOMAIN;
      const days = 30;
      let accordionActiveOptions = {};
      if (accordionCookie) {
        accordionActiveOptions = JSON.parse(accordionCookie);
      }

      accordionActiveOptions[data] = event;

      if (data === 'Collect' && event === false) {
        accordionActiveOptions.Keywords = true;
        accordionActiveOptions.Competitors = true;
        setTimeout(() => {
          this.reOpenDropdownCounter++;
        }, 300);
      }

      setCookie('sidebarMenuActiveOptions', JSON.stringify(accordionActiveOptions), cookieDomain, days);
    },
    subMenuAccordionAction(event, data) {
      this.userAccordionAction(event, data);
      this.recalculateHeightCounterHandler();
    },
    openOnInitCalculate(listName) {
      return this.accordionActiveOptions[listName] === undefined || this.accordionActiveOptions[listName] === true;
    },
    overheadType() {
      if (store.getters.userSubscription?.has_overhead_limits && (store.getters.userLimits?.max_apps?.used > store.getters.userLimits?.max_apps?.total)) {
        return 'appsOverhead';
      } else if (store.getters.userSubscription?.has_overhead_limits && (store.getters.userLimits?.max_keywords?.used > store.getters.userLimits?.max_keywords?.total)) {
        return 'keywordsOverhead';
      } else if (store.getters.userSubscription?.has_overhead_limits && (store.getters.userLimits?.max_competitors?.used > store.getters.userLimits?.max_competitors?.total)) {
        return 'competitorsOverhead';
      } else {
        return false;
      }
    },
    sidebarAccordionClicked(title) {
      if (title === 'Keywords') {
        this.$router.push({
          name: 'KeywordsTracking'
        });
      } else if (title === 'Competitors') {
        this.$router.push({
          name: 'MyCompetitors'
        });
      } else if (title === 'Applications') {
        this.$router.push({
          name: 'AnalyzeApp'
        });
      } else if (title === 'Reports') {
        this.$router.push({
          name: 'ImpressionsReport'
        });
      } else if (title === 'Dashboard') {
        this.$router.push({
          name: 'DashboardOverview'
        });
      }

      this.userAccordionAction(true, title);
    },
    setStringFormatting(str) {
      if (str && str !== '') {
        return str?.toLocaleString('ru-RU', {maximumFractionDigits: 0});
      }
    },
  },
  mounted() {
    const accordionCookie = getCookie('sidebarMenuActiveOptions');
    if (accordionCookie) {

      for (const key in this.sidebarRouteMenu) {
        const accordionOptions = JSON.parse(accordionCookie);
        this.accordionActiveOptions[this.sidebarRouteMenu[key]?.categoryTitle] = true;
        this.accordionActiveOptions = {...this.accordionActiveOptions, ...accordionOptions};
      }
    }

    this.betaTesterCookie = getCookie('betaTesterCookie');
  },
  computed: {
    ...mapGetters([
      'userData',
      'sidebarIsCollapsed',
      'currentApp',
      'userLimits',
      'currentAppReloaded',
      'dataCounts',
      'userSubscription',
    ]),
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>