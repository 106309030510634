import AppLocaleInfo from "@/api/objects/AppLocaleInfo";
import App from "@/api/objects/App";
import {storeByKey} from "@/utils/factories";

export class CustomCompetitor {

    constructor(
        appId,
        countryId,
        competitorId,
        competitorOriginId,

        logo,
        title,
        category,
        rating,
        store,
        storeUrl,
        competitorState = 'new',

        developer,
        votesCount,
        subTitle,

        installs,
        revenue,
        impressions,
    ) {
        this.appId = appId;
        this.countryId = countryId;
        this.competitorId = competitorId;
        this.competitorOriginId = competitorOriginId;

        this.logo = logo;
        this.title = title;
        this.subTitle = subTitle;
        this.category = category;
        this.rating = rating;
        this.store = store;
        this.storeUrl = storeUrl;
        this.competitorState = competitorState;

        this.developer = developer;
        this.votesCount = votesCount;

        this.installs = installs;
        this.revenue = revenue;
        this.impressions = impressions;

        this.checked = true;
    }

    makeAppObj() {
        let app = new App(this.competitorId, this.competitorOriginId, storeByKey(this.store));
        let appInfo = new AppLocaleInfo();
        appInfo.name = this.title;
        appInfo.logo = this.logo;

        app.locale_info = appInfo;

        return app;
    }

    isCompetitor() {
        return this.competitorState === 'competitor';
    }

    isNotCompetitor() {
        return this.competitorState === 'non-competitor' || this.competitorState === 'nonCompetitor';
    }

    setState(state) {
        this.competitorState = state;
    }

    /**
     * @deprecated
     */
    mutateToModalAppFormat() {
        return {
            id: this.competitorId,
            title: this.title,
            store: this.store,
            logo: this.logo,
        };
    }

    get developerName() {
        return this.developer.title;
    }
}