import {httpRequest} from "@/api";

export default {
    GET_RELEASES: 'api/metadata-journal/releases',
    SAVE_RELEASE: 'api/metadata-journal/releases',
    DELETE_RELEASE: 'api/metadata-journal/releases',
    GET_LOCALES: 'api/metadata-journal/locales',
    GET_METADATA_BY_LOCALES: 'api/metadata-journal/metadata',
    GET_ARAHIS_RELEASES: 'api/metadata-journal/arahis-releases',
    GET_SAVED_METAS: 'api/metadata-journal/saved-metas',
    GET_COUNTRIES_BY_LOCALES: 'api/metadata-journal/countries',
    IS_APP_STORE_API_CONNECTED: 'api/metadata-journal/app-store-api-connected',
    APP_STORE_UPDATE_RELEASES: 'api/metadata-journal/app-store-api-update-releases',

    getReleases: function (appId) {
        return httpRequest(
            'GET',
            process.env.VUE_APP_API_URL + this.GET_RELEASES + '?app_id=' + appId,
        )
    },
}