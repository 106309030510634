<template>
  <modal-component ref="globalModal"></modal-component>
  <clipboard-copy ref="copier"></clipboard-copy>
  <TooltipV2 ref="globalTooltip"/>

  <div class="wrapper">
    <svg-sprite/>
    <router-view v-slot="slotProps">
      <template v-if="maintenanceMode">
        <maintenance/>
      </template>
      <template v-else>
        <component :is="slotProps.Component"
                   v-if="loaded">
          <template v-slot:sidebar>
            <the-sidebar v-if="!isMobile"></the-sidebar>
          </template>
        </component>
        <template v-else>
          <div class="page-content"
               v-if="currentRouteName !== 'Authorization' && currentRouteName !== 'ResetPassword'">
            <the-new-header/>
            <main :class="[{'collapsed': sidebarIsCollapsed}, {'full-width': !loaded || isMobile}]">
              <progress-indicator>{{ loaderText }}</progress-indicator>
            </main>
          </div>
        </template>
      </template>
    </router-view>

    <custom-modal :opened="showModal"
                  :max-width="1050"
                  class-name="limits-plans-modal"
                  @closeModal="closeModal">
      <div class="modal-message textarea-block">
        <subscribe-plans/>
      </div>
    </custom-modal>

    <custom-modal :opened="showEmailConfirmationModal"
                  :max-width="420"
                  class-name="email-confirmation-modal"
                  @closeModal="closeModalBlock('showEmailConfirmationModal')">
      <div class="modal-message textarea-block">
        <div class="success-email-confirmation">
          <div class="icon">🎉</div>
          <div class="text">
            Congratulations!<br>
            Your email has been confirmed.<br>
            Please login!
          </div>
        </div>
      </div>
    </custom-modal>
  </div>
</template>

<style lang="scss" src="./styles.scss"></style>

<script>
import SvgSprite from '@/components/UI/SvgSprite/index';
import {httpRequest} from "@/api";
import {getCookie, deleteCookie, agentIsMobile, agentIsTablet, setCookie} from "@/utils/utils";
import {mapGetters} from 'vuex';
import SubscribePlans from "@/components/Modals/SubscribePlans";
import {UserAppsArrayCreator, UserCurrentAppObjectCreator} from "@/utils/factories";
import Maintenance from "@/components/UI/CountdownLayout/index";
import ModalComponent from "./components/Modals/ModalComponent.vue";
import RefsStore from "./utils/RefsStore";
import ClipboardCopy from "./components/ClipboardCopy.vue";
import myApps from "@/api/modules/my-apps";
import TooltipV2 from "@/components/UI/TooltipV2.vue";

export default {
  name: 'App',
  components: {
    TooltipV2,
    ClipboardCopy,
    ModalComponent,
    'svg-sprite': SvgSprite,
    'subscribe-plans': SubscribePlans,
    Maintenance,
  },
  data() {
    return {
      loaded: false,
      showModal: false,
      currentRouteName: null,
      loaderText: 'Loading',
      envMode: process.env.NODE_ENV,
      maintenanceMode: false,
      showEmailConfirmationModal: false,
      userResult: null,
      userAjaxSubscription: null,
    }
  },
  created() {
    localStorage.removeItem('currentAppId');
    if (process.env?.VUE_APP_DOWN_ALL_503 === 'yes') {
      this.maintenanceMode = true;
      return;
    }

    if (this.$route.query?.gtm_source && this.$route.query?.gtm_source === 'verify_email') {
      // this.showEmailConfirmationModal = true;
      deleteCookie('currentAppId');
      this.$router.push('/choose-plan');
      this.showEmailConfirmationModal = true;
    }

    this.currentRouteName = this.$route.name;
    this.loaderText = this.$route.meta?.loaderText ?? 'Loading...';

    if (this.envMode && this.envMode === 'production' && process.env.VUE_APP_HOTJAR_IS_ACTIVE && process.env.VUE_APP_HOTJAR_IS_ACTIVE === 'true') {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = "(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:3370627,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  },
  async mounted() {
    RefsStore.addRef('globalModal', this.$refs.globalModal);
    RefsStore.addRef('copier', this.$refs.copier);
    RefsStore.addRef('globalTooltip', this.$refs.globalTooltip);

    if (process.env?.VUE_APP_DOWN_ALL_503 === 'yes') {
      this.maintenanceMode = true;
      return;
    }

    if (this.$route.query?.gtm_source && this.$route.query?.gtm_source === 'verify_email') {
      try {
        if (process?.env?.NODE_ENV === 'production') {
          //TODO продумать что бы нельзя было долбить подстановкой параметров
          //event('sign_up');
        }
      } catch (e) {
        console.error(e);
      }
    }

    if (getCookie('token')) {
      this.$store.dispatch('SET_TOKEN', getCookie('token'));

      if (!this.userResult?.is_confirmed_some_plan && this.$route.name === 'PlanSelectionPage') {
        setCookie('currentAppId', 'undefined', null, 1);
      } else if (this.userResult?.is_confirmed_some_plan && this.$route.name === 'PlanSelectionPage') {
        this.$router.push('/');
      } else {
        await this.sendUserSubscriptionPromisesBundle();
      }

      this.$store.dispatch('SET_USER_SUBSCRIPTION', this.userAjaxSubscription);

      if (this.userResult?.message?.includes('Unauthenticated')) {
        deleteCookie('token');
        this.$store.dispatch('SET_TOKEN', null);
        this.$router.go('/login');
      } else if (this.userResult) {
        this.$store.dispatch('SET_USER_DATA', this.userResult);

        if (this.userAjaxSubscription?.has_overhead_limits === true
         && this.$route.name !== 'MyApps'
         && this.$route.name !== 'KeywordsTracking'
         && this.$route.name !== 'Competitors') {
          this.$router.push('/');
        }

        if (this.envMode && this.envMode === 'production' && process.env.VUE_APP_HOTJAR_IS_ACTIVE && process.env.VUE_APP_HOTJAR_IS_ACTIVE === 'true') {
          let script = document.createElement('script');
          const userId = this.userResult?.id ?? null;
          script.type = 'text/javascript';
          script.innerHTML = `window.hj('identify', ${userId}, {});`;
          if (userId) {
            document.getElementsByTagName('head')[0].appendChild(script);
          }
        }

        if (this.envMode && this.envMode === 'production' && !window.location.href.includes('test-asolytics')) {

          if (this.userResult?.id) {
            try {
              this.sendGaDataToBackend();
            } catch (e) {
              console.error(e);
            }

            window.intercomSettings = {
              api_base: "https://api-iam.intercom.io",
              app_id: "iwjtsd5v",
              user_id: this.userResult?.id,
              name: this.userResult?.name, // Full name
              email: this.userResult?.email, // Email address
              created_at: this.userResult?.created_at, // Signup date as a Unix timestamp
              user_hash: this.userResult?.intercom_hash,

              apps_count: this.userLimits.max_apps?.used ?? null,
              tracking_count: this.userLimits.max_keywords?.used ?? null,
              competitors_count: this.userLimits.max_competitors?.used ?? null,
            };

            try {
              this.gtag('config', this.$gtm.id, {user_id: this.userResult.id});
            } catch (e) {
              console.error(e);
            }

            if (this.userResult.email) {
              let script = document.createElement('script');
              script.type = 'text/javascript';
              script.setAttribute('id', 'profitwell-js');
              script.setAttribute('data-pw-auth', '90d83b12db0f06fc205a504a95f0fae3');
              script.innerHTML = `(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)}; a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);})(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');profitwell('start', { 'user_email': '${this.userResult.email}' });`
              document.getElementsByTagName('head')[0].appendChild(script);
            }
          } else if (!this.userResult?.id) {
            window.intercomSettings = {
              api_base: "https://api-iam.intercom.io",
              app_id: "iwjtsd5v",
            };
          }

          let script = document.createElement('script');
          script.type = 'text/javascript';
          script.innerHTML = `(function(){var w=window;var ic=w.Intercom;if(typeof ic===\"function\"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/iwjtsd5v';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`
          document.getElementsByTagName('head')[0].appendChild(script);
        }
      }
    }

    window.addEventListener('resize', () => {
      this.$store.dispatch('SET_IS_MOBILE', agentIsMobile());
      this.$store.dispatch('SET_IS_TABLET', agentIsTablet());
    });

    if (this.$route.name !== 'Authorization' && this.$route.name !== 'ResetPassword') {
      const currentAppId = getCookie('currentAppId');
      if (currentAppId && currentAppId !== 'undefined') {
        await this.$store.dispatch('INIT_LATEST_COUNTRY', currentAppId);
        await myApps.checkCurrentApp(this.currentCountry?.code);
      } else {
        this.$store.dispatch('INIT_SELECTED_APP');
        if (this.myApps.length > 0) {
          await this.$router.push('/');
        }
      }
    }

    if (!this.userResult?.is_confirmed_some_plan && this.$route.name !== 'PlanSelectionPage') {
      this.$router.push('/choose-plan');
    }

    if (this.userResult?.is_confirmed_some_plan
     && this.$route.name !== 'AddFirstAppPage'
     && this.$route.name !== 'Profile'
     && (getCookie('currentAppId') === 'null' || getCookie('currentAppId') === null)
     && Object?.keys(this.myApps)?.length === 0) {

      this.$router.push('/add-application');
    }

    setTimeout(() => {
      this.loaded = true;

      this.$store.dispatch('GLOBAL_LOAD_FINISHED', this.loaded);
    }, 500);

    if (this.userLimits !== null && this.userAjaxSubscription?.has_overhead_limits === false && this.showLimits()) {
      setTimeout(() => {
        this.showModal = true;
      }, 1000);
    }
  },
  methods: {
    sendGaDataToBackend() {
      let promises = [
        new Promise(
          (resolve) => {
            this.gtag('get', 'G-T84EJ0W7NE', 'client_id', (client_id) => {
              resolve(client_id);
            });
          }
        ),
        new Promise(
          (resolve) => {
            this.gtag('get', 'G-T84EJ0W7NE', 'session_id', (session_id) => {
              resolve(session_id);
            });
          }
        ),
      ];

      Promise.all(promises).then((values) => {
        let client_id = values[0];
        let session_id = values[1];
        if (client_id && session_id) {
          httpRequest(
              'post',
              process.env.VUE_APP_API_URL + 'api/profiles/upd-gtag',
              {
                sessionId: session_id,
                googleId: client_id,
              }
          );
        }
      });
    },
    closeModal() {
      this.showModal = false;
      document.body.style.overflow = 'auto';
    },
    closeModalBlock(name) {
      this.showEmailConfirmationModal = false;
    },
    async fetchUserLimits() {
      const userLimits = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.user.USER_LIMITS);

      if (!userLimits.hasOwnProperty('errors')) {
        this.$store.dispatch('SET_USER_LIMITS', userLimits);
      }
    },
    async sendUserSubscriptionPromisesBundle() {
      const promiseUrls = [
        {
          url: process.env.VUE_APP_API_URL + this.$ApiUrls.user.USER,
          method: 'GET',
        },
        {
          url: process.env.VUE_APP_API_URL + 'api/subscription',
          method: 'GET',
        },
        {
          url: process.env.VUE_APP_API_URL + this.$ApiUrls.user.USER_LIMITS,
          method: 'GET',
        },
        {
          url: process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS_NEW,
          method: 'GET',
        },
      ];

      await this.$store.dispatch('FETCH_PROMISE_ALL_SETTLED', promiseUrls);

      const result = this.promiseSettledData?.data[0]?.value;
      const subscription = this.promiseSettledData?.data[1]?.value;
      const userLimits = this.promiseSettledData?.data[2]?.value;
      const appsList = this.promiseSettledData?.data[3]?.value;

      if (this.promiseSettledData.success) {
        if (result) {
          this.userResult = {...result};
          if (this.userResult?.message?.includes('Unauthenticated')) {
            deleteCookie('token');
            this.$store.dispatch('SET_TOKEN', null);
            this.$router.go('/login');
          }
        }

        if (subscription) {
          this.userAjaxSubscription = {...subscription};
        }

        if (userLimits && !userLimits.hasOwnProperty('errors')) {
          this.$store.dispatch('SET_USER_LIMITS', userLimits);
        }

        if (appsList) {
          this.$store.dispatch('SET_MY_APPS', UserAppsArrayCreator(appsList) ?? []);
        }

      }
    },
    showLimits(type = 'default') {
      if (type === 'default') {
        if (this.userLimits?.max_apps?.used > this.userLimits?.max_apps?.total) {
          return true;
        } else if (this.userLimits?.max_competitors?.used > this.userLimits?.max_competitors?.total) {
          return true;
        } else if (this.userLimits?.max_keywords?.used > this.userLimits?.max_keywords?.total) {
          return true;
        }
      } else {
        if (this.userLimits?.max_apps?.used >= this.userLimits?.max_apps?.total) {
          return true;
        } else if (this.userLimits?.max_competitors?.used >= this.userLimits?.max_competitors?.total) {
          return true;
        } else if (this.userLimits?.max_keywords?.used >= this.userLimits?.max_keywords?.total) {
          return true;
        }
      }

      if (JSON.stringify(this.userLimits) !== '{}') {
        return false;
      } else {
        return false;
      }
    },
  },
  computed: {
    ...mapGetters([
      'userData',
      'sidebarIsCollapsed',
      'isMobile',
      'myApps',
      'currentApp',
      'currentAppId',
      'promiseSettledData',
      'userLimits',
      'userLimitsError',
      'userLimitsManualChanged',
      'plansLimits',
      'updateUserLimits',
      'currentCountry',
      'userSubscription',
    ])
  },
  watch: {
    async $route(to, from) {
      if (from?.name === "Authorization") {
        const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.user.USER);

        if (!result?.message?.includes('Unauthenticated')) {
          this.$store.dispatch('SET_USER_DATA', result);
        }
      }
      if (localStorage.getItem('doNotUpdatePage')) {
        localStorage.removeItem('doNotUpdatePage');
      }
    },
    async currentAppId(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (!this.currentCountry?.code) {
          await this.$store.dispatch('INIT_LATEST_COUNTRY', this.currentAppId);
        }
        await myApps.checkCurrentApp(this.currentCountry?.code);
        await this.fetchUserLimits();
      }
    },
    async userLimitsError(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.userLimits !== null && this.userSubscription?.has_overhead_limits === false) {
          this.showModal = true;
        }
      }
    },
    async userLimitsManualChanged(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.showModal = true;
      }
    },
    async updateUserLimits(newVal, oldVal) {
      if (newVal !== oldVal) {
        await this.fetchUserLimits();
        const subscription = await httpRequest('GET', process.env.VUE_APP_API_URL + 'api/subscription');
        this.$store.dispatch('SET_USER_SUBSCRIPTION', subscription);
      }
    }
  }
}
</script>