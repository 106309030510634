import keywordsAutosuggest from "./modules/autosuggest.js";
import rankingKeywords from "./modules/ranking-keywords.js";
import keywordsTracking from "./modules/keywords-tracking.js";
import liveSearch from "./modules/live-search.js";
import myApps from "./modules/my-apps.js";
import appRanking from "./modules/app-ranking.js";
import user from "./modules/user.js";
import metadataKeywords from "./modules/metadata-keywords.js";
import recommendedKeywords from "./modules/recommended-keywords.js";
import dashboard from "@/api/modules/dashboard.js";
import competirorsFinder from "./modules/competitors-finder.js";
import competitorState from "./modules/competitor-state.js";
import metaEditor from "./modules/meta-editor.js";
import exportsUrls from "./modules/exports.js";
import translateUrls from "./modules/translates.js";
import relatedKeywords from "./modules/related-keywords.js";
import reports from "./modules/reports.js";
import reviewSummaries from "./modules/reviews-summaries";
import appNotifications from "./modules/app-notifications";
import integrations from "./modules/integrations";
import metadataJournal from "./modules/metadata-journal";

export const apiUrls = {
    rankingKeywords,
    keywordsAutosuggest,
    keywordsTracking,
    liveSearch,
    myApps,
    appRanking,
    user,
    metadataKeywords,
    recommendedKeywords,
    dashboard,
    competirorsFinder,
    metaEditor,
    exportsUrls,
    translateUrls,
    relatedKeywords,
    reports,
    reviewSummaries,
    competitorState,
    appNotifications,
    integrations,
    metadataJournal,
};