import Pusher from "pusher-js";

export default class Sockets {
    connection = null;
    subscribedChannels = {};

    client() {
        if (!this.connection) {
            this.connection = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
                cluster: 'eu'
            });
        }

        return this.connection;
    }

    channel(channelName) {
        if (!this.subscribedChannels[channelName]) {
            this.subscribedChannels[channelName] = this.client().subscribe(channelName);
        }

        return this.subscribedChannels[channelName];
    }

    closeConnection() {
        if (!this.connection) {
            return;
        }
        if (this.connection.state !== 'connected') {
            return;
        }

        for (let channel in this.subscribedChannels) {
            this.connection.unsubscribe(channel);
        }
        this.connection.disconnect();
    }

    userChannel(id) {
        return this.channel(`user_channel.${id}`);
    }

    globalChannel() {
        return this.channel('global_channel');
    }
}