<template>
  <div class="app-billet-wrap">

    <div class="app-billet"
         @click="appClickHandler($event, itemData.id)">
      <div class="app-top-block">
        <div class="app-logo">
          <app-image
                :width="54"
                :height="54"
                :lazy-src="cropImg(itemData.logo, '54x54', itemData?.store?.key)"
          />
        </div>
        <div class="app-details">
          <div class="app-name">{{ itemData.title ?? '' }}</div>

          <div class="ratings">
            <div class="aso-score">
              <div class="css-icons aso-icon"></div>
              {{ setAsoScore() }}
            </div>

            <div class="aso-score">
              <div class="css-icons rating-icon"></div>
              {{ setAppRating() }}
            </div>
          </div>

        </div>
        <div v-if="hasOverhead === false"
             class="delete-app"
             @click.stop="deleteAppClicked(itemData.id ?? null)">
          <svg-icon icon="close"/>
        </div>
      </div>

      <div class="app-bottom-block">
        <div class="app-bottom-subblock">
          <div class="app-bottom-item">
            <div class="info-title">Tracking keywords:</div>
            <div class="info-value">
              <template v-if="ajaxDataLoaded">
                {{ setStringFormatting(dataCounts?.trackingKeywords?.total?.value) }}
              </template>
              <template v-else>
                <preloader-table-small/>
              </template>
            </div>
          </div>
          <div class="app-bottom-item">
            <div class="info-title">Recommended:</div>
            <div class="info-value">
              <template v-if="ajaxDataLoaded">
                {{ setStringFormatting(dataCounts?.recommendedKeywords?.all?.value) }}
              </template>
              <template v-else>
                <preloader-table-small/>
              </template>
            </div>
          </div>
          <div class="app-bottom-item">
            <div class="info-title">Competitors:</div>
            <div class="info-value">
              <template v-if="ajaxDataLoaded">
                {{ setStringFormatting(competitorsCount?.count) }}
              </template>
              <template v-else>
                <preloader-table-small/>
              </template>
            </div>
          </div>
        </div>
        <div class="app-bottom-subblock">
          <div class="app-bottom-item">
            <div class="info-title">Installs:</div>
            <div class="info-value">
              <div v-if="hideInstalls"
                   class="metric-value upgrade-text"
                   @click="openPlansModal($event)">
                Pro Plan
                <svg-icon icon="arrow-right"/>
              </div>
              <template v-else-if="ajaxDataLoaded">
                {{ shortenDigits(installsRevenue?.installs) }}
              </template>
              <template v-else>
                <preloader-table-small/>
              </template>
            </div>
          </div>
          <div class="app-bottom-item">
            <div class="info-title">Revenue:</div>
            <div class="info-value">
              <div v-if="hideInstalls"
                   class="upgrade-text"
                   @click="openPlansModal($event)">
                Pro Plan
                <svg-icon icon="arrow-right"/>
              </div>
              <template v-else-if="ajaxDataLoaded">
                {{ shortenDigits(installsRevenue?.revenue) }}
              </template>
              <template v-else>
                <preloader-table-small/>
              </template>
            </div>
          </div>
          <div class="app-bottom-item">
            <div class="info-title">
              <a class="app-id"
                 @click.stop
                 :href="itemData.store_link ?? '/'"
                 target="_blank">
                <span class="link-text">View in</span>

                <img v-if="itemData?.store?.key === 'APP_STORE'"
                     src="@/assets/images/icons/app_store.png"
                     alt="Store icon"
                     class="store-icon"
                     width="12"
                     height="12">
                <img v-else
                     src="@/assets/images/icons/google_play.png"
                     alt="Store icon"
                     class="store-icon"
                     width="12"
                     height="12">
              </a>
            </div>
          </div>
        </div>
        <slot></slot>
      </div>

    </div>
  </div>
</template>

<script>
import {shortenDigits, formatDate, cropImage} from "@/utils/utils";
import {mapGetters} from "vuex";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import {apiUrls} from "@/api/api";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";

export default {
  name: "AppBillet",
  components: {
    SvgIcon,
    'preloader-table-small': PreloaderTableSmall,
  },
  props: {
    itemData: {
      type: Object
    },
    currentCountry: {
      type: Object
    },
    hasOverhead: {
      type: Boolean,
      default: false
    },
  },
  emits: ['delete-app-clicked', 'app-clicked'],
  data() {
    return {
      competitorsCount: {},
      installsRevenue: {},
      dataCounts: {},
      ajaxDataLoaded: false,
    }
  },
  methods: {
    formatDate(val) {
      return formatDate(val);
    },
    shortenDigits(val) {
      if (val !== null && val !== '') {
        return shortenDigits(val);
      } else {
        return 'n/a';
      }
    },
    deleteAppClicked(id) {
      this.$emit('delete-app-clicked', id);
    },
    appClickHandler(e, id) {
      if (this.hasOverhead === false) {
        this.$emit('app-clicked', id);
      }
    },
    cropImg(imageUrl, size, store) {
      return cropImage(imageUrl, size, store);
    },
    setAsoScore() {
      if (this.itemData?.aso?.total) {
        let aso = +this.itemData?.aso?.total;
        return aso.toFixed(0);
      }
      return 'n/a';
    },
    setAppRating() {
      return this.itemData?.rating?.toFixed(1);
    },
    setStringFormatting(str) {
      if (str !== null && str !== '') {
        return str?.toLocaleString('ru-RU', {maximumFractionDigits: 0});
      } else {
        return 'n/a';
      }
    },
    async sendPromisesBundle() {
      const promiseUrls = [
        {
          url: process.env.VUE_APP_API_URL + 'api/profiles/application/custom-competitors-count?app_id=' + this.itemData?.id + '&is_competitor=1',
          method: 'GET',
        },
        {
          url: process.env.VUE_APP_API_URL + 'api/app-dashboard/installs-revenue-counts?app_id=' + +this.itemData?.id + '&country_code=' + this.currentCountry?.code,
          method: 'GET',
        },
        {
          url: process.env.VUE_APP_API_URL + apiUrls.recommendedKeywords.DATA_COUNTS + '?country_code=' + this.currentCountry?.code + '&app_id=' + +this.itemData?.id,
          method: 'GET',
        },
      ];

      await this.$store.dispatch('FETCH_PROMISE_ALL_SETTLED', promiseUrls);
      const competitorsCount = this.promiseSettledData?.data[0]?.value;
      const installsRevenue = this.promiseSettledData?.data[1]?.value;
      const dataCounts = this.promiseSettledData?.data[2]?.value;

      if (this.promiseSettledData.success) {
        if (competitorsCount) {
          this.competitorsCount = {...competitorsCount};
        }

        if (installsRevenue) {
          this.installsRevenue = {
            installs: installsRevenue?.installs ?? null,
            revenue: installsRevenue?.revenue ?? null,
          };
        }

        if (dataCounts) {
          this.dataCounts = {...dataCounts};
        }
      }

      this.ajaxDataLoaded = true;

    },
    openPlansModal(event) {
      event.stopPropagation();
      this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    },
  },
  async mounted() {
    await this.sendPromisesBundle();
  },
  computed: {
    ...mapGetters([
      'promiseSettledData',
      'userSubscription',
    ]),
    hideInstalls() {
      return this.userSubscription?.plan_name === 'Free';
    },
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>