<script setup>
import { ref, onMounted, onUnmounted } from "vue";

const isVisible = ref(false);
const tooltipContent = ref(null);
const position = ref({ top: 0, left: 0 });
const contentType = ref(null);
const initiator = ref(null);
const closestScrollable = ref(null);
const observer = ref(null);

const getScrollableParent = (node) => {
  while (node && node !== document.body) {
    const { overflowY, overflowX } = getComputedStyle(node);
    if (/(auto|scroll|overlay)/.test(overflowY + overflowX)) {
      return node;
    }
    node = node.parentElement;
  }
  return window;
};

const updateTooltipPosition = () => {
  const rect = initiator.value.getBoundingClientRect();
  position.value = {
    top: rect.bottom + window.scrollY + 5,
    left: rect.left + window.scrollX + rect.width / 2
  };
};

const showTooltip = (event, content) => {
  hideTooltip()

  tooltipContent.value = content;
  initiator.value = event.target;
  closestScrollable.value = getScrollableParent(event.target);
  closestScrollable.value.addEventListener("scroll", updateTooltipPosition);
  window.addEventListener("resize", updateTooltipPosition);

  if (!observer.value) {
    observer.value = new IntersectionObserver(([entry]) => {
      if (entry.intersectionRatio < 0.50) hideTooltip();
    }, {
      root: closestScrollable.value,
      threshold: 0.1,
      rootMargin: "-70px 0px -10px 0px"
    })
  }

  observer.value.observe(initiator.value);

  if (typeof content === "string") {
    contentType.value = "string";
  } else {
    contentType.value = "component";
  }
  updateTooltipPosition()

  isVisible.value = true;
};

const hideTooltip = () => {
  isVisible.value = false;
  observer.value?.unobserve(initiator.value);
  closestScrollable.value?.removeEventListener("scroll", updateTooltipPosition);
  window.removeEventListener("resize", updateTooltipPosition);

  tooltipContent.value = null;
};

const onClickOutside = (event) => {
  if (!event.target.closest("[data-role=tooltip]") && initiator.value !== event.target) {
    hideTooltip();
  }
};

onMounted(() => {
  document.addEventListener("click", onClickOutside);
});
onUnmounted(() => {
  document.removeEventListener("click", onClickOutside);
});

defineExpose({ showTooltip, hideTooltip });
</script>

<template>
  <Teleport to="body">
    <div v-show="isVisible" data-role="tooltip" class="tooltip" :style="{ top: position.top + 'px', left: position.left + 'px' }">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
        <path d="M-6.99382e-07 8L7 -6.11959e-07L14 8L-6.99382e-07 8Z" fill="white"/>
      </svg>

      <div v-if="tooltipContent">
        <component :is="tooltipContent" v-if="contentType === 'component'" />
        <template v-else>{{ tooltipContent }}</template>
      </div>
    </div>
  </Teleport>
</template>

<style scoped>
.tooltip {
  position: absolute;

  border-radius: 4px;
  background: #FFF;
  padding: 12px 16px;
  box-shadow: 0 4px 20px 0 rgba(160, 190, 221, 0.32);
  transition: none;

  transform: translateX(-50%);
  z-index: 2222;

  > svg {
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>