<template>
  <div class="alert-wrap" v-if="text.length > 0">
    <div class="alert-block">
      <transition>
        <div class="text" v-if="show" v-html="text"></div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  emits: [
    'hide'
  ],
  props: {
    text: String,
  },
  data() {
    return {
      show: false,
    }
  },
  watch: {
    text() {
      this.show = true;
      setTimeout(() => {
        this.$emit('hide');
        this.show = false;
      }, 3000);
    }
  },
}
</script>

<style scoped lang="scss">
  .alert-block {
    z-index: 1000;
    bottom: 50px;
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    left: 0;

    .text {
      background: var(--neutral-900);
      border-radius: 4px;
      color: var(--neutral-0);
      padding: 5px 20px;
    }
  }
</style>